import { Middleware } from "redux";
import { STORE_PATH_AUTH } from "constants/store-constants";
import { get } from "lodash";

const persistActions = [
  `${STORE_PATH_AUTH.AUTH}/setCredentials`,
  `${STORE_PATH_AUTH.AUTH}/resetAuthState`,
] as const;

const localStorageMiddleware: Middleware = (store) => {
  return (next) => (action) => {
    const result = next(action);
    if (persistActions.includes(result.type)) {
      const pathArray = result.type.split("/").slice(0, -1);
      const key = pathArray.join(".");
      const localStorageKey = pathArray.join("/");

      localStorage.setItem(
        localStorageKey,
        JSON.stringify(get(store.getState(), key))
      );
    } else if (result.type === `${STORE_PATH_AUTH.AUTH}/resetAuthState`) {
      localStorage.clear();

      return;
    }
    return result;
  };
};

export default localStorageMiddleware;
