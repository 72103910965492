import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import AppRoutes from "routes/AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store/store";
import { FullPageLoadingProvider } from "provider/FullPageLoadingProvider";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <FullPageLoadingProvider>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </FullPageLoadingProvider>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
