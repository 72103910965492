import { useNavigate } from "react-router-dom";

import { Button, Flex, Heading, Text } from "@chakra-ui/react";

function Page404() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      pt={8}
      minH="100%"
      data-testid="page-404"
    >
      <Heading fontSize="150px" fontWeight="300" data-testid="heading-404">
        404
      </Heading>
      <Text mb={6} fontSize="x-large" mt={3} data-testid="not-found-text">
        Seems, this page doesn’t exist.
      </Text>
      <Button
        colorScheme="primary"
        width="200px"
        size="lg"
        fontWeight="500"
        onClick={handleClick}
        data-testid="home-button"
      >
        Home
      </Button>
    </Flex>
  );
}

export default Page404;
