import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ROUTE_PATH } from "constants/routes-constants";
import PrivateRoute from "components/private-route/PrivateRoute";
import Page404 from "pages/Page404";

const LoginPage = React.lazy(() => import("pages/Auth/LoginPage"));
const SignUpPage = React.lazy(() => import("pages/Auth/SignUpPage"));
const ForgotPasswordPage = React.lazy(
  () => import("pages/Auth/ForgotPasswordPage")
);
const PasswordRecoveryPage = React.lazy(
  () => import("pages/Auth/PasswordRecoveryPage")
);
const PrivateRoutes = React.lazy(() => import("routes/ChatRoutes"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Navigate to={ROUTE_PATH.CHAT} />} />
        <Route path={ROUTE_PATH.LOGIN} element={<LoginPage />} />
        <Route path={ROUTE_PATH.SIGN_UP} element={<SignUpPage />} />
        <Route
          path={ROUTE_PATH.FORGOT_PASSWORD}
          element={<ForgotPasswordPage />}
        />
        <Route path={ROUTE_PATH.RECOVERY} element={<PasswordRecoveryPage />} />

        <Route
          path={`${ROUTE_PATH.CHAT}/*`}
          element={
            <PrivateRoute>
              <PrivateRoutes />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
