import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { STORE_PATH_CHAT } from "constants/store-constants";
import { Chat, ChatState } from "features/Chat/types/chat-types";
import { FilteredRequest } from "types/sort-types";
import { FILTER_OPTIONS, ORDER_OPTIONS } from "constants/filter-constants";

const sliceName = STORE_PATH_CHAT.CHAT;

const savedChatSlice = localStorage.getItem(sliceName);

const defaultState = {
  onlineUsers: [],
  sortOptions: {
    sortBy: FILTER_OPTIONS.LAST_MESSAGE.sortBy,
    order: ORDER_OPTIONS.ASC,
  },
  searchedMessages: null,
};

const initialState: ChatState = savedChatSlice
  ? (JSON.parse(savedChatSlice) as ChatState)
  : defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setOnlineUsers: (state, { payload }: PayloadAction<string[]>) => {
      state.onlineUsers = payload;
    },
    setSortingOptions: (state, { payload }: PayloadAction<FilteredRequest>) => {
      state.sortOptions = payload;
    },
    setSearchedMessages: (state, { payload }: PayloadAction<Chat[] | null>) => {
      state.searchedMessages = payload;
    },
  },
});

export const { setOnlineUsers, setSortingOptions, setSearchedMessages } =
  slice.actions;

export default slice.reducer;
