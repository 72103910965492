import { useAppSelector } from "hooks/useRedux";
import { Navigate } from "react-router-dom";
import { ROUTE_PATH } from "constants/routes-constants";
import React from "react";

interface Props {
  children: JSX.Element;
}

const PrivateRoute = ({ children }: Props) => {
  const token = useAppSelector(
    (state) => state.authModule.auth.tokens.accessToken
  );

  return !!token ? (
    children
  ) : (
    <Navigate data-testid="navigate-login" to={ROUTE_PATH.LOGIN} replace />
  );
};

export default PrivateRoute;
