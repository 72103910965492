export enum ORDER_OPTIONS {
  ASC = "asc",
  DESC = "desc",
}

export enum FILTER_TYPES {
  NAME = "NAME",
  LAST_MESSAGE = "LAST_MESSAGE",
}

export const FILTER_OPTIONS: Record<string, { label: string; sortBy: string }> =
  {
    [FILTER_TYPES.NAME]: {
      label: "Name",
      sortBy: "name",
    },
    [FILTER_TYPES.LAST_MESSAGE]: {
      label: "Last Message",
      sortBy: "createdAt",
    },
  } as const;
