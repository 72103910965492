import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

import { STORE_PATH_AUTH } from "constants/store-constants";
import { AuthState } from "features/Auth/types/auth-types";
import { DecodedToken, Tokens } from "types/token-types";

const sliceName = STORE_PATH_AUTH.AUTH;

const savedTokens = localStorage.getItem(sliceName);
const parsedTokens = savedTokens
  ? (JSON.parse(savedTokens) as AuthState)
  : null;
const decodedSavedToken = parsedTokens?.tokens?.accessToken
  ? (jwtDecode(parsedTokens.tokens.accessToken) as DecodedToken)
  : null;

const defaultTokens = {
  accessToken: null,
  refreshToken: null,
};

const initialState: AuthState =
  parsedTokens && decodedSavedToken
    ? { tokens: parsedTokens.tokens, decodedToken: decodedSavedToken }
    : { tokens: defaultTokens, decodedToken: null };

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCredentials: (state, { payload: tokens }: PayloadAction<Tokens>) => {
      const decodedToken = tokens.accessToken
        ? (jwtDecode(tokens.accessToken) as DecodedToken)
        : null;
      state.tokens = tokens;
      state.decodedToken = decodedToken;
    },
    resetAuthState: (state) => {
      state.tokens = defaultTokens;
      state.decodedToken = null;
    },
  },
});

export const { setCredentials, resetAuthState } = slice.actions;

export default slice.reducer;
