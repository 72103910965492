import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  BaseQueryFn,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import config from "config/config";

import store, { RootState } from "store/store";
import toast from "utils/toast";
import produce from "immer";
import { API_TAGS } from "constants/api-tags-constants";
import { Mutex } from "async-mutex";
import { AUTH_PATH_URL } from "constants/api-path-constants";
import { redirect } from "react-router-dom";
import { ROUTE_PATH } from "constants/routes-constants";
import { resetAuthState } from "features/Auth/store/slices/auth.slice";
import { ApiResponse, QueryResponseType, ServerErrors } from "types/api-types";

export interface IQueryArgs extends FetchArgs {
  showToast?: boolean;
  redirectOnNotFoundError?: boolean;
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${config.apiUrl}/api/`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).authModule.auth.tokens.accessToken;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const mutex = new Mutex();

export const logout = async () => {
  store.dispatch(resetAuthState());
  redirect(ROUTE_PATH.LOGIN);
};

export const showErrorMessage = (err?: ServerErrors) => {
  const { message } = err || {};

  toast({
    status: "error",
    title: "Error",
    description: message || "Oh no, there was an error!",
    isClosable: true,
  });
};

const refreshTokenRequest = async (api: BaseQueryApi, extraOptions: {}) => {
  const response = await baseQuery(
    {
      url: AUTH_PATH_URL.REFRESH,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          store.getState().authModule.auth.tokens.accessToken
        }`,
      },

      body: {
        refreshToken: store.getState().authModule.auth.tokens.refreshToken,
      },
    },
    api,
    extraOptions
  );

  if (response.error) {
    throw new Error();
  }

  return getTransformedResponse(response);
};

export const getTransformedResponse = (response: QueryResponseType) => {
  return produce(response, (draft) => {
    if (draft.data) {
      draft.data = (draft.data as ApiResponse).result as typeof response.data;
    }
  });
};

const makeRequest = async (
  args: IQueryArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const response = await baseQuery(args, api, extraOptions);
  return getTransformedResponse(response);
};

const handleErrors = async ({
  showToast,
  api,
  args,
  extraOptions,
  response,
}: {
  showToast: boolean;
  args: IQueryArgs;
  api: BaseQueryApi;
  extraOptions: {};
  response: QueryResponseType;
}) => {
  let transformedResponse;
  const err = (response.error?.data as ApiResponse)?.error;

  if (err?.code === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        await refreshTokenRequest(api, extraOptions);

        transformedResponse = await makeRequest(args, api, extraOptions);
      } catch {
        await logout();
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      transformedResponse = await makeRequest(args, api, extraOptions);
    }
  } else if (showToast) {
    showErrorMessage(err);
  }

  return transformedResponse as QueryResponseType;
};

const baseQueryWithToast: BaseQueryFn<IQueryArgs> = async (
  { showToast = true, ...args }: IQueryArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  await mutex.waitForUnlock();

  let transformedResponse;
  transformedResponse = await makeRequest(args, api, extraOptions);

  if (transformedResponse.error) {
    transformedResponse = await handleErrors({
      api,
      showToast,
      args,
      response: transformedResponse,
      extraOptions,
    });
  }

  return transformedResponse;
};

const api = createApi({
  baseQuery: baseQueryWithToast,
  endpoints: () => ({}),

  tagTypes: Object.values(API_TAGS),
});

export default api;
