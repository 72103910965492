export enum STORE_MODULES {
  AUTH = "authModule",
  CHAT = "chatModule",
  USER = "userModule",
}

export enum AUTH_SLICES {
  AUTH = "auth",
}

export enum CHAT_SLICES {
  CHAT = "chat",
}

export const STORE_PATH_AUTH = {
  AUTH: `${STORE_MODULES.AUTH}/${AUTH_SLICES.AUTH}`,
};

export const STORE_PATH_CHAT = {
  CHAT: `${STORE_MODULES.CHAT}/${CHAT_SLICES.CHAT}`,
};
