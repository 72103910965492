enum PATH_URL {
  PUBLIC = "public",
  UNAUTHORIZED = "unauthorized",
  LOGIN = "login",
  DECLINE = "decline",
  AUTH = "auth",
  USER = "user",
  SOCIAL = "social",
  PASSWORD = "password",
  AVATAR = "upload",
  FORGOT = "forgot",
  RECOVER = "recovery",
  REFRESH = "refresh",
  LIST = "list",
  CHAT = "chat",
  MESSAGES = "messages",
  SEND = "send",
  NEW = "new",
  SEARCH = "search",
}

export const AUTH_PATH_URL = {
  LOGIN: `/${PATH_URL.PUBLIC}/${PATH_URL.UNAUTHORIZED}/${PATH_URL.LOGIN}`,
  SOCIAL: `/${PATH_URL.PUBLIC}/${PATH_URL.UNAUTHORIZED}/${PATH_URL.LOGIN}/${PATH_URL.SOCIAL}`,
  AUTH: `/${PATH_URL.PUBLIC}/${PATH_URL.UNAUTHORIZED}/${PATH_URL.AUTH}`,
  FORGOT: `/${PATH_URL.PUBLIC}/${PATH_URL.UNAUTHORIZED}/${PATH_URL.FORGOT}`,
  RECOVER: `/${PATH_URL.PUBLIC}/${PATH_URL.UNAUTHORIZED}/${PATH_URL.RECOVER}`,
  REFRESH: `/${PATH_URL.PUBLIC}/${PATH_URL.UNAUTHORIZED}/${PATH_URL.REFRESH}`,
  DECLINE: `/${PATH_URL.PUBLIC}/${PATH_URL.DECLINE}`,
};

export const USER_PATH_URL = {
  USER: `/${PATH_URL.PUBLIC}/${PATH_URL.USER}`,
  LIST: `/${PATH_URL.PUBLIC}/${PATH_URL.USER}/${PATH_URL.LIST}`,
  CHANGE_PASSWORD: `/${PATH_URL.PUBLIC}/${PATH_URL.USER}/${PATH_URL.PASSWORD}`,
  AVATAR: `/${PATH_URL.PUBLIC}/${PATH_URL.USER}/${PATH_URL.AVATAR}`,
};

export const CHAT_PATH_URL = {
  SEND_MESSAGE: `/${PATH_URL.PUBLIC}/${PATH_URL.CHAT}/${PATH_URL.MESSAGES}/${PATH_URL.SEND}`,
  CHATS: `${PATH_URL.PUBLIC}/${PATH_URL.CHAT}/${PATH_URL.LIST}`,
  MESSAGES: `${PATH_URL.PUBLIC}/${PATH_URL.CHAT}/${PATH_URL.MESSAGES}`,
  CHAT_USER: `${PATH_URL.PUBLIC}/${PATH_URL.CHAT}/${PATH_URL.USER}`,
  CREATE_CHAT: `/${PATH_URL.PUBLIC}/${PATH_URL.CHAT}/${PATH_URL.MESSAGES}/${PATH_URL.NEW}`,
  CHAT: `${PATH_URL.PUBLIC}/${PATH_URL.CHAT}`,
  SEARCH: `${PATH_URL.PUBLIC}/${PATH_URL.CHAT}/${PATH_URL.SEARCH}`,
};
