import { configureStore } from "@reduxjs/toolkit";
import localStorageMiddleware from "store/middlewares/local-storage";
import api from "api/api";
import { STORE_MODULES } from "constants/store-constants";
import { authFeatureReducer } from "features/Auth/store/auth-feature-reducer";
import { chatFeatureReducer } from "features/Chat/store/chat-feature-reducer";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [STORE_MODULES.AUTH]: authFeatureReducer,
    [STORE_MODULES.CHAT]: chatFeatureReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      api.middleware,
      localStorageMiddleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
