import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: {
      400: "#4C6FFF",
      500: "#2E5BFF",
      600: "#1943EF",
      650: "#12215E",
      700: "#040D32",
      800: "#031B4E",
      alpha: {
        500: "rgba(46,91,255,0.1)",
      },
    },
    secondary: {
      0: "#FFFFFF",
      100: "#F5F6F7",
      200: "#EAEDF3",
      300: "#8E94A7",
      400: "#8798AD",
      500: "#8097B1",
      600: "#6F7C8B",
      700: "#374A59",
      800: "#1A1E2C",
      alpha: {
        600: "rgba(111, 124, 139, 0.5)",
        750: "rgba(35, 35, 39, 0.84)",
      },
    },
  },
  fonts: {
    body: "Roboto",
    heading: "Roboto",
  },
  fontSizes: {
    xs: "0.75rem", // 12px
    md: "0.875rem", // 14px
    lg: "1rem", // 16px
    xl: "1.25rem", // 20px
    "2xl": "1.625rem", // 26px
    "4xl": "1.875rem", // 30px
  },
  radii: {
    none: "0",
    sm: "0.125rem",
    base: "0.15rem",
    md: "0.18rem",
    lg: "0.32rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    "10xl": "12.5rem",
    full: "9999px",
  },
  shadows: {
    sm: "0px 1px 4px rgba(0, 0, 0, 0.1)",
  },
  styles: {
    global: {
      body: {
        height: "100%",
      },
      html: {
        height: "100%",
      },
      "#root": {
        height: "100%",
      },
    },
  },
  components: {
    Button: {
      baseStyles: {
        fontSize: "1.125rem",
      },
      sizes: {
        lg: {
          h: 14,
        },
        md: {
          h: 12,
          minW: 12,
          px: 4,
        },
        sm: {
          h: 9,
        },
      },
    },
    Input: {
      variants: {
        filled: {
          field: {
            backgroundColor: "secondary.100",
            placeholder: "secondary.600",
            _hover: {
              backgroundColor: "secondary.200",
            },
          },
        },
      },
    },
    NumberInput: {
      variants: {
        filled: {
          field: {
            backgroundColor: "secondary.100",
            placeholder: "secondary.600",
            _hover: {
              backgroundColor: "secondary.200",
            },
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: "lg",
        color: "secondary.600",
      },
      sizes: {
        sm: {
          fontSize: "md",
        },
      },
    },
    Link: {
      baseStyle: {
        color: "primary.400",
        fontWeight: "medium",
        _hover: {
          textDecoration: "none",
          color: "primary.600",
        },
      },
    },
    Checkbox: {
      sizes: {
        lg: {
          control: {
            w: 6,
            h: 6,
          },
          icon: {
            fontSize: "0.8rem",
          },
        },
      },
    },
    Text: {
      variants: {
        link: {
          baseStyle: {
            color: "primary.400",
            fontWeight: "medium",
            _hover: {
              textDecoration: "none",
              color: "primary.600",
            },
          },
        },
      },
    },
    Tabs: {
      sizes: {
        sm: {
          tab: {
            fontWeight: "medium",
            fontSize: "0.875rem",
            py: 3,
            px: 3.5,
          },
          tablist: {
            borderBottomWidth: "1px !important",
          },
          tabpanel: {
            px: 0,
            py: 9,
          },
        },
      },
    },
  },
});

export default theme;
